<template>
    <ion-item lines="none" button @click='showLoginActionSheet'>
        <ion-icon slot="start" :ios="logIn" :md="logIn" />
        <ion-label>{{$t('interface.login')}}</ion-label>
    </ion-item>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { logIn } from 'ionicons/icons';
import { Auth } from '@/services/AuthService';
import {
    IonItem,
    IonIcon,
    IonLabel, actionSheetController
} from '@ionic/vue';
import {useI18n} from "vue-i18n";
import store from "@/store";

export default defineComponent({
  name: "LoginButton",
  setup() {
      const { t }  = useI18n()
      const showLoginActionSheet = async () => {
          const actionSheet = await actionSheetController.create({
              header: t('loginWith'),
              buttons: [
                  { text: t('usernameOrPassword'), handler: () => Auth.Instance.signIn()},
                  { text: t('qrCode'), handler: () => {
                      store.commit('showQRScanner', {title: t('loginWith'), type: 'login-with'})
                  }}
              ],
          });

          await actionSheet.present();
      };

      return { logIn, showLoginActionSheet }
  },
  components: {
      IonItem,
      IonIcon,
      IonLabel
  }
})
</script>

<style lang="scss" scoped>
ion-item {
  --background: transparent !important;
}
</style>
