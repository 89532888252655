import { PushNotifications } from '@capacitor/push-notifications';
import { isPlatform } from '@ionic/vue';
import {Auth} from "@/services/AuthService";
import axios from "axios";
import {AuthActions} from "ionic-appauth";
import PushNotificationActionHandler from "@/helpers/push/PushNotificationActionHandler";
import store from './../store';
import {Capacitor} from "@capacitor/core";

export default class FcmService {
    async initPush() {
        if (isPlatform('capacitor')) {
            await this.registerNotifications();
        }
    }

    async removeListeners() {
        if (Capacitor.getPlatform() === 'web') return

        await PushNotifications.removeAllListeners();
    }

    async registerNotifications() {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
            throw new Error('User denied notifications permission!');
        }

        await this.addListeners()

        await PushNotifications.register();
    }

    async addListeners() {
        await PushNotifications.addListener('registration', async (token) => {

            // eslint-disable-next-line no-console
            console.info('>>> FCM REGISTRATION TOKEN RECEIVED: ', token.value);
            if (! localStorage.getItem('fcmToken')) localStorage.setItem('fcmToken', token.value)

            // eslint-disable-next-line no-console
            console.info('>>> LOADING USER INFO...')
            await Auth.Instance.loadUserInfo();

            Auth.Instance.events$.subscribe(async (action) => {
                if (action.action === AuthActions.LoadUserInfoSuccess) {
                    const tokenResponse = localStorage.getItem('token_response')
                    const accessToken = JSON.parse(tokenResponse || '')?.access_token

                    if (!action.user.CloudApiUri || !accessToken) {
                        localStorage.removeItem('fcmToken')
                        localStorage.removeItem('fcmTokenSaved')

                        console.warn('>>> The current user does not have the CloudApiUri property. Skipping...')

                        return
                    }

                    if (localStorage.getItem('fcmToken') === token.value && localStorage.getItem('fcmTokenSaved')) {
                        return
                    }

                    const config = {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + accessToken
                        }
                    };

                    try{
                        const url = `${action.user.CloudApiUri}/api/v1/notifications/register`

                        console.info('>>> Sending the FCM Token to the backend...')
                        console.info(`>>> POST URL: ${url}`)

                        await axios.post(url, {
                            token: token.value,
                            timestamp: new Date()
                        }, config)
                          .then(resp => {
                              console.info(">>> TOKEN SAVED! ")
                              console.info(">>> RESPONSE: ", resp)
                              localStorage.setItem('fcmToken', token.value)
                              localStorage.setItem('fcmTokenSaved', true)
                          })
                    } catch(error){
                        localStorage.removeItem('fcmTokenSaved')

                        // eslint-disable-next-line no-console
                        console.error(Object.keys(error), error.message);
                    }
                }
            })
        });

        await PushNotifications.addListener('registrationError', err => {
            // eslint-disable-next-line no-console
            console.error('Registration error: ', err.error);
        });

        await PushNotifications.addListener('pushNotificationReceived', notification => {
            store.commit('closeToast')
            setTimeout(() => {
                store.commit('showToast', {
                    title: notification.title,
                    message: notification.body,
                    duration: 5000,
                    action: notification.data.action,
                    args: notification.data.args
                })
            }, 400)

            const data = notification.data;
            const webviewIframeEl = document.getElementById('webviewIframe')
            webviewIframeEl?.contentWindow.postMessage({ scope: data.action, args: JSON.parse(data.args) }, '*')
        });

        await PushNotifications.addListener(
          "pushNotificationActionPerformed",
          (action) => {
              const data = action.notification.data;
              try {
                  const keys = Object.keys(data)
                  if (keys.includes("action") && keys.includes("args")) {
                      const args = JSON.parse(data.args)
                      const handler = new PushNotificationActionHandler(data.action, args);
                      handler.run();
                      localStorage.setItem('pushNotificationActionPerformed', JSON.stringify(true))
                      setTimeout(() => {
                          localStorage.removeItem('pushNotificationActionPerformed')
                      }, 10 * 1000)
                  }
              } catch (error) {
                  // eslint-disable-next-line no-console
                  console.error(error)
              }
          }
        );
    }
}
