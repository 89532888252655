import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createSlots as _createSlots, createBlock as _createBlock, Suspense as _Suspense } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "center-content"
}
const _hoisted_2 = { class: "logo-wrapper" }
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_toolbar = _resolveComponent("app-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_TheLoader = _resolveComponent("TheLoader")!
  const _component_product_card = _resolveComponent("product-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_app_footer = _resolveComponent("app-footer")!
  const _component_PushNotificationInit = _resolveComponent("PushNotificationInit")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_app_toolbar, {
            title: _ctx.$t('interface.dashboard')
          }, null, 8, ["title"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { class: "ion-align-items-center" }, {
        default: _withCtx(() => [
          (_ctx.schoolLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_TheLoader)
              ]))
            : (_openBlock(), _createBlock(_component_ion_grid, { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_row, null, {
                    default: _withCtx(() => [
                      (_ctx.showSchoolCard)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 0,
                            size: "12",
                            "size-md": "6",
                            class: "ion-no-padding"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_row, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_col, { size: "12" }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_product_card, {
                                        color: "#c51824",
                                        image: require('@/assets/png/school-main.png'),
                                        title: _ctx.school.name,
                                        subtitle: _ctx.$t('DeineFahrschule'),
                                        "has-main-action": false,
                                        email: _ctx.school.email,
                                        website: _ctx.school.website,
                                        phone: _ctx.school.phone,
                                        facebook: _ctx.school.facebook,
                                        instagram: _ctx.school.instagram,
                                        youtube: _ctx.school.youtube,
                                        tiktok: _ctx.school.tikTok,
                                        snapchat: _ctx.school.snapChat,
                                        action: ()=>{}
                                      }, _createSlots({
                                        text: _withCtx(() => [
                                          _createElementVNode("span", null, _toDisplayString(_ctx.school.street), 1),
                                          _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
                                          _createElementVNode("span", null, _toDisplayString(_ctx.school.zipCode) + " " + _toDisplayString(_ctx.school.city), 1)
                                        ]),
                                        _: 2
                                      }, [
                                        (_ctx.formattedSchoolLogo)
                                          ? {
                                              name: "description-image",
                                              fn: _withCtx(() => [
                                                _createElementVNode("div", _hoisted_2, [
                                                  _createElementVNode("img", {
                                                    class: "logo",
                                                    src: _ctx.formattedSchoolLogo,
                                                    alt: _ctx.school.name
                                                  }, null, 8, _hoisted_3)
                                                ])
                                              ]),
                                              key: "0"
                                            }
                                          : undefined
                                      ]), 1032, ["image", "title", "subtitle", "email", "website", "phone", "facebook", "instagram", "youtube", "tiktok", "snapchat"])
                                    ]),
                                    _: 1
                                  }),
                                  (_ctx.inLandscapeOrientation)
                                    ? (_openBlock(), _createBlock(_component_ion_col, {
                                        key: 0,
                                        size: "12"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_product_card, {
                                            color: "#005288",
                                            image: require('@/assets/png/clicklearn_icon.png'),
                                            title: _ctx.$t('ClickAndLearn'),
                                            subtitle: _ctx.$t('ClickAndLearnDescription'),
                                            text: _ctx.$t('ClickAndLearnText'),
                                            action: _ctx.openClickAndLearn,
                                            "has-settings": false,
                                            "settings-url": "/preferences/click-and-learn"
                                          }, null, 8, ["image", "title", "subtitle", "text", "action"])
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _createVNode(_component_ion_col, {
                        size: "12",
                        "size-md": "6"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_product_card, {
                            color: "#E5BB25",
                            image: require('@/assets/png/cockpit.png'),
                            title: _ctx.$t('Cockpit'),
                            subtitle: _ctx.$t('CockpitDescription'),
                            text: _ctx.$t('CockpitText'),
                            action: _ctx.openCampus,
                            "has-settings": false,
                            "settings-url": "/preferences/campus-central"
                          }, null, 8, ["image", "title", "subtitle", "text", "action"])
                        ]),
                        _: 1
                      }),
                      (!_ctx.inLandscapeOrientation)
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 1,
                            size: "12",
                            "size-md": "6"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_product_card, {
                                color: "#005288",
                                image: require('@/assets/png/clicklearn_icon.png'),
                                title: _ctx.$t('ClickAndLearn'),
                                subtitle: _ctx.$t('ClickAndLearnDescription'),
                                text: _ctx.$t('ClickAndLearnText'),
                                action: _ctx.openClickAndLearn,
                                "has-settings": false,
                                "settings-url": "/preferences/click-and-learn"
                              }, null, 8, ["image", "title", "subtitle", "text", "action"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      false
                        ? (_openBlock(), _createBlock(_component_ion_col, {
                            key: 2,
                            size: "12",
                            "size-md": "6",
                            "size-lg": "4"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_product_card, {
                                color: "#560f20",
                                image: require('@/assets/png/elearning_icon.png'),
                                title: _ctx.$t('eLearning'),
                                subtitle: _ctx.$t('eLearningTitle'),
                                text: _ctx.$t('eLearningDescription'),
                                action: _ctx.openELearning
                              }, null, 8, ["image", "title", "subtitle", "text", "action"])
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
        ]),
        _: 1
      }),
      _createVNode(_component_app_footer),
      (_openBlock(), _createBlock(_Suspense, null, {
        default: _withCtx(() => [
          _createVNode(_component_PushNotificationInit)
        ]),
        _: 1
      }))
    ]),
    _: 1
  }))
}